import api from '@services/api'

export default {
  get(axios, params, query, config) {
    return api.get(axios, '/manager/organizations/:organization_id/address', params, query, config)
  },

  update(axios, params, payload, config) {
    return api.update(
      axios,
      '/manager/organizations/:organization_id/address',
      params,
      payload,
      config
    )
  },
}
